
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import { Link } from 'gatsby';
import "./meet-our-brandies.scss";

const MeetOurBrandies = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("meet-our-brandies:metaTitle")}>
      <>
        <HeroImg customClass="meet-our-brandies-hero" backgroundImage="bg/meet-our-brandies-hero.jpg">
          {<>
            <div className="position-title-section">
              <h2 className="meet-our-brandies-subtitle">{t("meet-our-brandies:heroSubtitle")}</h2>
              <h1 className="meet-our-brandies-title box-two">{t("meet-our-brandies:heroTitle")}</h1>
              <p>{t("meet-our-brandies:heroTextOne")}</p>
              <p>{t("meet-our-brandies:heroTextTwo")}</p>
              <p>{t("meet-our-brandies:heroTextThree")}</p>
            </div>
            <div>

              <div className="meet-our-brandies-position">
                <div>
                  <Link to={t("routes:gordaApricot")}>
                    <img src="/img/apricot.png" alt="apricot" />
                    <h1 className="brandy">{t("meet-our-brandies:brandy")}</h1>
                    <h3 className="brandy-title">{t("meet-our-brandies:apricot")}</h3>
                  </Link>
                </div>

                <div>
                  <Link to={t("routes:gordaPlum")}>
                    <img src="/img/plum.png" alt="plum" />
                    <h1 className="brandy">{t("meet-our-brandies:brandy")}</h1>
                    <h3 className="brandy-title">{t("meet-our-brandies:plum")}</h3>
                  </Link>
                </div>

                <div>
                  <Link to={t("routes:gordaQuince")}>
                    <img src="/img/quince.png" alt="quince" />
                    <h1 className="brandy">{t("meet-our-brandies:brandy")}</h1>
                    <h3 className="brandy-title">{t("meet-our-brandies:quince")}</h3>
                  </Link>
                </div>

                <div>
                  <Link to={t("routes:gordaAron")}>
                    <img src="/img/aron.png" alt="aron" />
                    <h1 className="brandy">{t("meet-our-brandies:brandy")}</h1>
                    <h3 className="brandy-title">{t("meet-our-brandies:aron")}</h3>
                  </Link>
                </div>

              </div>
            </div>
          </>}
        </HeroImg>
      </>
    </MainLayout>
  )
}

export default MeetOurBrandies;